<template>
  <div class="ev2-box" style="max-width: 400px">
    <div class="header">
      <div class="hcol1">
        <div class="title">Estatística de Clientes</div>
        <div class="subtitle">Resumo sobre os clientes</div>
      </div>
      <div class="hcol2">
        <i class="fal fa-refresh cursor-pointer" @click="load" v-if="!loading" />
        <sl-loading v-else class="size-18" content="" />
      </div>
    </div>
    <div class="body m-t">
      <div class="task-count">
        <div class="count">
          <div class="lbl">Total de clientes:</div>
          <div class="val-container">
            <div class="value bg-black text-white">{{ stats.total }}</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">Ativos:</div>
          <div class="val-container">
            <div class="value started">{{ stats.ativos }}</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">Homologando:</div>
          <div class="val-container">
            <div class="value pending">{{ stats.homolog }}</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">Suspensos:</div>
          <div class="val-container">
            <div class="value">{{ stats.suspensos }}</div>
          </div>
        </div>
        <div class="hr" />
        <div class="header m-t">
          <div class="hcol1">
            <div class="title">Índice de satisfação</div>
          </div>
          <div class="hcol2">
          </div>
        </div>
        <div class="count m-t-lg">
          <div class="lbl">
            <div style="max-width: 80px"><satisfacao-rating disable :cliente="{indiceSatisfacao: 5}" size="xs" /></div>
          </div>
          <div class="val-container2">
            <div class="value2 text-green">{{indice5Porcentagem}}% ({{ stats.indice5 }})</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">
            <div style="max-width: 80px"><satisfacao-rating disable :cliente="{indiceSatisfacao: 4}" size="xs" /></div>
          </div>
          <div class="val-container2">
            <div class="value2 text-green-4">{{indice4Porcentagem}}% ({{ stats.indice4 }})</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">
            <div style="max-width: 80px"><satisfacao-rating disable :cliente="{indiceSatisfacao: 3}" size="xs" /></div>
          </div>
          <div class="val-container2">
            <div class="value2 text-orange">{{indice3Porcentagem}}% ({{ stats.indice3 }})</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">
            <div style="max-width: 80px"><satisfacao-rating disable :cliente="{indiceSatisfacao: 2}" size="xs" /></div>
          </div>
          <div class="val-container2">
            <div class="value2 text-orange-10">{{indice2Porcentagem}}% ({{ stats.indice2 }})</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">
            <div style="max-width: 80px"><satisfacao-rating disable :cliente="{indiceSatisfacao: 1}" size="xs" /></div>
          </div>
          <div class="val-container2">
            <div class="value2 text-negative">{{indice1Porcentagem}}% ({{ stats.indice1 }})</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {financeiroStats} from "@/domain/gerencia/stats/services";
import SlLoading from "components/layout/components/Loading.vue";
import SatisfacaoRating from "components/clientes/components/cliente/include/SatisfacaoRating.vue";

export default {
  name: "RequerAtencao",
  components: {SatisfacaoRating, SlLoading},
  data () {
    return {
      loading: true,
      stats: {
        total: 0,
        ativos: 0,
        suspensos: 0,
        homolog: 0,
        indice1: 0,
        indice2: 0,
        indice3: 0,
        indice4: 0,
        indice5: 0
      }
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    totalClientes() {
      return Number(this.stats.indice1) + Number(this.stats.indice2) + Number(this.stats.indice3) + Number(this.stats.indice4) + Number(this.stats.indice5);
    },
    indice1Porcentagem() {
      return ((Number(this.stats.indice1) / this.totalClientes) * 100).toFixed(2);
    },
    indice2Porcentagem() {
      return ((Number(this.stats.indice2) / this.totalClientes) * 100).toFixed(2);
    },
    indice3Porcentagem() {
      return ((Number(this.stats.indice3) / this.totalClientes) * 100).toFixed(2);
    },
    indice4Porcentagem() {
      return ((Number(this.stats.indice4) / this.totalClientes) * 100).toFixed(2);
    },
    indice5Porcentagem() {
      return ((Number(this.stats.indice5) / this.totalClientes) * 100).toFixed(2);
    }
  },
  methods: {
    load() {
      this.loading = true
      financeiroStats()
          .then(response => {
            this.stats = Object.assign(this.stats, response.data)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    }
  }
}
</script>
