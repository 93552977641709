<template>
  <div class="ev2-box" style="max-width: 400px">
    <div class="header">
      <div class="hcol1">
        <div class="title">Requer Atenção</div>
        <div class="subtitle">Situações que precisam de atenção imediata</div>
      </div>
      <div class="hcol2">
        <i class="fal fa-refresh cursor-pointer" @click="load" v-if="!loading" />
        <sl-loading v-else class="size-18" content="" />
      </div>
    </div>
    <div class="body m-t">
      <div class="task-count">
        <div class="count">
          <div class="lbl">Clientes sem <strong>Contrato formalizado</strong>:</div>
          <div class="val-container">
            <div class="value bg-black text-white">{{ stats.semContratoAssinado }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {clienteAlertStats} from "@/domain/gerencia/stats/services";
import SlLoading from "components/layout/components/Loading.vue";

export default {
  name: "RequerAtencao",
  components: {SlLoading},
  data () {
    return {
      loading: true,
      stats: {
        semContratoAssinado: 0,
      }
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      clienteAlertStats()
          .then(response => {
            this.stats = Object.assign(this.stats, response.data)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    }
  }
}
</script>
